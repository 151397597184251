import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
// Hooks
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
// Utils
import Sentry from '../../../utils/sentry'

export const createInnovationSubmissionMutation = gql`
  mutation createInnovationSubmission(
    $callForSubmissionId: String!
    $submissionId: String
    $productName: String
  ) {
    createInnovationSubmission(
      callForSubmissionId: $callForSubmissionId
      submissionId: $submissionId
      productName: $productName
    ) {
      id
    }
  }
`

const useCreateInnovationSubmission = callForSubmissionId => {
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const [upsertInnovationSubmission, { loading }]: any[] = useMutation(
    createInnovationSubmissionMutation,
    {
      refetchQueries: ['callsForSubmission', 'innovationSubmissions'],
      awaitRefetchQueries: true,
      onError: error => {
        Sentry.captureException(error)
        let errorMessage
        if (error?.message?.length) errorMessage = error.message
        setToastErrorMessage(errorMessage || t('error:defaultMutation'))
      }
    }
  )

  const createInnovationSubmission = async (
    submissionId?: string,
    productName?: string
  ) => {
    const results = await upsertInnovationSubmission({
      variables: {
        submissionId,
        callForSubmissionId,
        productName
      }
    })

    return results?.data?.createInnovationSubmission
  }

  return [createInnovationSubmission, loading]
}

export default useCreateInnovationSubmission
