import React, { useState, useCallback, FC } from 'react'

import { clone } from 'ramda'
import { Text, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

// Hooks
import useTranslation from '../../../hooks/useTranslation'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useHasPermission from '../../../hooks/useHasPermission'
import useSubmitDialogState from '../../../hooks/useSubmitDialogState'
import useLastSubmittedSubmission from '../../../hooks/useLastSubmittedSubmission'
import { submissionQuery } from '../../../screens/Submission/hooks/useSubmissionQuery'
import useUpdateSubmissionStatusMutation from '../../../screens/Submission/hooks/useUpdateSubmissionStatusMutation'
import useCreateSubmissionPdfMutation from '../../../screens/Submission/hooks/useCreateSubmissionPdfMutation'
// Components
import Modal from '../Modal'
import { Flex } from '../../FlexBox'
import Sentry from '../../../utils/sentry'
import Button from '../../../ui-library/Button'
import TouchableIcon from '../../icon/TouchableIcon'
import {
  BannerAlert,
  Title
} from '../../../screens/InnovatorDirectory/components/SharedComponents'
// Types
import { InnovationSubmissionStatusEnum, RoleEnum } from '../../../types'

interface SubmitDialogProps {
  innovationSubmissionId: string
  delegationId?: string
  callForSubmissionId?: string
  openDialog?: boolean
  triggerClose?: () => void
}

const CloseIcon = styled(TouchableIcon).attrs(props => ({
  name: 'close',
  width: props.theme.fontSizes[4],
  height: props.theme.fontSizes[4],
  color: props.theme.colors.text2
}))`
  position: absolute;
  top: -10px;
  right: -10px;
`
const Description = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSizes[5]}px;
    margin-bottom: ${theme.space[3]}px;
  `}
`

const containerStyle = {
  flexBasis: '48%'
}
const buttonStyle = {
  width: '100%'
}

const REDIRECT_CURATE_TAB = [RoleEnum.Admin, RoleEnum.InnovationAdmin]

const SubmitDialog: FC<SubmitDialogProps> = ({
  innovationSubmissionId,
  delegationId,
  callForSubmissionId,
  openDialog,
  triggerClose
}) => {
  const [error, setError] = useState<string | null>(null)

  const isSmallScreen = useIsSmallScreen()
  const { space } = useTheme()
  const titleStyle = isSmallScreen ? { fontSize: 15 } : {}
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const {
    openDialog: submitStateOpenDialog,
    closeDialog
  } = useSubmitDialogState()
  const { setLastSubmittedSubmission } = useLastSubmittedSubmission()
  const linkToScreen = useLinkToScreen()
  const [
    updateInnovationSubmissionStatus,
    loading
  ] = useUpdateSubmissionStatusMutation({
    update: (cache, { data }) => {
      const queryVariables = {
        id: innovationSubmissionId,
        withSubmissionReviewsAverages: false
      }
      const query: any = cache.readQuery({
        query: submissionQuery,
        variables: queryVariables
      })

      // Just in case the cache query doesn't change
      if (
        data?.updateInnovationSubmissionStatus?.hasBeenSubmitted &&
        !query?.innovationSubmission?.hasBeenSubmitted
      ) {
        const clonedQuery = clone(query)
        // Validate if there are innovationSubmission results in the cached query
        if (clonedQuery?.innovationSubmission) {
          clonedQuery.innovationSubmission.hasBeenSubmitted =
            data?.updateInnovationSubmissionStatus?.hasBeenSubmitted
          cache.writeQuery({
            query: submissionQuery,
            variables: queryVariables,
            clonedQuery
          })
        }
      }
    },
    onError: mutationError => {
      Sentry.captureException(mutationError)
      setError(mutationError?.message || t('error:defaultMutation'))
    }
  })
  const {
    createSubmissionPDF,
    loading: loadingCreatePDF
  } = useCreateSubmissionPdfMutation()

  const [submitted, setSubmitted] = useState(false)
  const dialogClose = triggerClose || closeDialog
  const onClose = useCallback(() => {
    setError(null)
    setSubmitted(false)
    dialogClose()
  }, [])

  const shouldRedirectToCurateTabs = useHasPermission(REDIRECT_CURATE_TAB)

  return (
    <Modal
      close={onClose}
      testIDModal={submitted ? 'subReceived' : 'submitModal'}
      open={openDialog || submitStateOpenDialog}
      styles={{
        minWidth: '350px',
        maxWidth: '450px',
        padding: '15px 24px'
      }}
    >
      <CloseIcon onPress={onClose} />

      <Flex padding={space[3]}>
        <Title>
          {submitted
            ? t('callsForSubmission:dialog:submitted')
            : t('callsForSubmission:dialog:title')}
        </Title>
        <View style={{ marginTop: space[3] }}>
          {submitted ? (
            <>
              <Description>
                {t('callsForSubmission:dialog:successMessage')}
              </Description>
              <Description>
                {t('callsForSubmission:dialog:updateProduct')}
              </Description>
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  marginTop: space[3]
                }}
              >
                <Button
                  title={t('callsForSubmission:dialog:skip')}
                  testID="confirmSkip"
                  type="outline"
                  onPress={() => {
                    onClose()
                    linkToScreen('SubmissionList')
                  }}
                  containerStyle={containerStyle}
                  buttonStyle={buttonStyle}
                  titleStyle={titleStyle}
                />
                <Button
                  title={t('callsForSubmission:dialog:continue')}
                  testID="confirmCreate"
                  type="solid"
                  onPress={async () => {
                    onClose()
                    setLastSubmittedSubmission({
                      submissionId: innovationSubmissionId,
                      delegationId: delegationId || null
                    })
                    linkToScreen('InnovatorDirectoryProfile', {
                      companyId: currentUser?.person?.innovationCompany?.id
                    })
                  }}
                  containerStyle={containerStyle}
                  buttonStyle={buttonStyle}
                  titleStyle={titleStyle}
                  isProcessing={loading || loadingCreatePDF}
                />
              </Flex>
            </>
          ) : (
            <>
              <Description>
                {t('callsForSubmission:dialog:description')}
              </Description>
              {error ? <BannerAlert message={error} /> : null}
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  marginTop: space[3]
                }}
              >
                <Button
                  title={t('callsForSubmission:dialog:cancelBtn')}
                  type="outline"
                  onPress={onClose}
                  containerStyle={containerStyle}
                  buttonStyle={buttonStyle}
                  titleStyle={titleStyle}
                />
                <Button
                  title={t('callsForSubmission:dialog:submitBtn')}
                  type="solid"
                  testID="confirmSubmit"
                  onPress={async () => {
                    setError(null)
                    const result = await updateInnovationSubmissionStatus(
                      innovationSubmissionId,
                      InnovationSubmissionStatusEnum.submitted
                    )

                    const pdfResult = await createSubmissionPDF(
                      innovationSubmissionId
                    )
                    if (pdfResult?.errors) {
                      Sentry.captureException(
                        new Error(
                          `Error while creating submission PDF for: ${innovationSubmissionId}`
                        )
                      )
                      Sentry.captureException(pdfResult?.errors)
                      pdfResult?.errors && console.error(pdfResult?.errors)
                    }

                    if (result?.errors) {
                      result?.errors && console.error(result.errors)
                    } else if (shouldRedirectToCurateTabs) {
                      onClose()
                      linkToScreen(
                        callForSubmissionId
                          ? 'CurationSubmissions'
                          : 'CurateCFSList',
                        callForSubmissionId ? { id: callForSubmissionId } : {}
                      )
                    } else {
                      setSubmitted(true)
                    }
                  }}
                  containerStyle={containerStyle}
                  buttonStyle={buttonStyle}
                  titleStyle={titleStyle}
                  isProcessing={loading || loadingCreatePDF}
                />
              </Flex>
            </>
          )}
        </View>
      </Flex>
    </Modal>
  )
}

export default SubmitDialog
