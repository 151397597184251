import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { InnovationSubmission } from '../../../types'
import useFormAnswerSubscription from './useFormAnswerSubscription'

export const submissionQuery = gql`
  query innovationSubmission(
    $id: String!
    $withSubmissionReviewsAverages: Boolean!
  ) {
    innovationSubmission(id: $id) {
      id
      callForSubmissionId
      callForSubmission {
        status
        name
        formId
        cutFormId
        stepDateRanges {
          curationStep
          openAt
          closeAt
        }
        delegations {
          id
          name
        }
        marketSegments {
          id
          name
        }
        currentStep {
          name
          openAt
          closeAt
        }
        disclaimer
        disclaimerEventLocation
        disclaimerInnovatorFee
        disclaimerContactEmail
      }
      canSubmitAdvisorFeedback
      step
      status
      updatedAt
      pdfCloudinaryId
      hasBeenSubmitted
      hasBeenFastPassed
      canEdit
      canSubmit
      canDelete
      company {
        name
        companyProfile {
          url
          domains {
            name
          }
          country {
            name
          }
          yearFounded
          funding
          employeeCount
        }
        isAlumni
      }
      submissionInfo {
        name
        category
      }
      submissionReview {
        id
        userId
        submissionId
        avgScore
        formAnswerId
      }
      cohorts {
        group {
          id
        }
        deletedById
        isInvite
        isSpotlight
      }
      submissionAverage @include(if: $withSubmissionReviewsAverages) {
        wass
        submissionScores {
          value
          question {
            type
            questionText
            configData
          }
        }
        totalFav
        configData
      }
    }
  }
`

interface SubmissionObject {
  submission: InnovationSubmission
  loading: boolean
  hasChanged: boolean
  submissionUpdatedBy?: string
  setHasChanged: ([boolean, string]) => void
  refetch: (params: any) => void
}

const useSubmissionQuery = (
  id,
  withSubmissionReviewsAverages = false,
  options = {}
): SubmissionObject => {
  const { t } = useTranslation()

  const { data, loading, refetch } = useQuery(submissionQuery, {
    variables: { id, withSubmissionReviewsAverages },
    skip: !id,
    errorMessage: t('error:submission:getSubmission'),
    ...options
  })

  const {
    hasChanged,
    submissionUpdatedBy,
    setHasChanged
  } = useFormAnswerSubscription({
    innovationSubmissionId: id
  })

  return {
    submission: data?.innovationSubmission,
    loading,
    hasChanged,
    setHasChanged,
    submissionUpdatedBy,
    refetch
  }
}

export default useSubmissionQuery
