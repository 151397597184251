import { useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { Route } from '@react-navigation/native'

// Recoil atoms
import {
  hasUnsavedChangesAtom as hasUnsavedAtom,
  unsavedAdvisorChangesAtom as unsavedAtom,
  UnsavedAdvisorChangesAtomData
} from '../../recoil/unsavedAdvisorChangesAtomFamily'

// Types
interface useGetUnsavedAdvisorChangesI {
  submitForm?: Function | null
  unsavedValues: any | null
  shouldInitForm: boolean
  hasUnsavedChanges: boolean
  formRouteState: Route<any> | null
}

export const useGetUnsavedAdvisorChanges = (): useGetUnsavedAdvisorChangesI => {
  const unsavedState = useRecoilValue(unsavedAtom)
  const hasUnsavedState = useRecoilValue(hasUnsavedAtom)

  // Memo
  const unsavedValues = useMemo(() => unsavedState?.unsavedValues, [
    unsavedState?.unsavedValues
  ])
  const submitForm = useMemo(() => unsavedState?.submitForm, [
    unsavedState?.unsavedValues
  ])
  const shouldInitForm = useMemo(() => !!unsavedState?.shouldInitForm, [
    unsavedState?.shouldInitForm
  ])
  const formRouteState = useMemo(() => unsavedState?.formRouteState || null, [
    unsavedState?.formRouteState
  ])
  const hasUnsavedChanges = useMemo(() => hasUnsavedState, [hasUnsavedState])

  return {
    submitForm,
    unsavedValues,
    formRouteState,
    shouldInitForm,
    hasUnsavedChanges
  }
}

interface useSetUnsavedAdvisorChangesI {
  setUnsavedAdvisorState: (newState: UnsavedAdvisorChangesAtomData) => void
  setHasUnsaved: (bool: boolean) => void
  resetState: () => void
}

export const useSetUnsavedAdvisorChanges = (): useSetUnsavedAdvisorChangesI => {
  const setUnsavedValues = useSetRecoilState(unsavedAtom)
  const setHasUnsavedState = useSetRecoilState(hasUnsavedAtom)

  const setUnsavedAdvisorState = (newState: UnsavedAdvisorChangesAtomData) => {
    setUnsavedValues(newState)
  }
  const setHasUnsaved = (bool: boolean) => {
    setHasUnsavedState(bool)
  }

  const resetState = () => {
    setUnsavedValues({
      submitForm: null,
      unsavedValues: null,
      formRouteState: null
    })
    setHasUnsavedState(false)
  }

  return {
    setUnsavedAdvisorState,
    setHasUnsaved,
    resetState
  }
}
