import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { ScrollView } from 'react-native'
import {
  useRoute,
  useFocusEffect,
  useNavigation
} from '@react-navigation/native'
import styled from 'styled-components/native'
// Middleware
import withNavigationPermissions from '../../../navigation/withNavigationPermissions'
// Hook
import ReviewModal from './components/ReviewModal'
import { StyledFlex } from './components/SharedComponents'
import useTranslation from '../../../hooks/useTranslation'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useCallForSubmissionQuery from '../hooks/useCallForSubmissionQuery'
import useSubmissionQuery from '../../Submission/hooks/useSubmissionQuery'
import useFormAnswerQuery from '../../Submission/hooks/useFormAnswerQuery'
import { useSetUnsavedAdvisorChanges } from '../../../hooks/unsavedAdvisorChanges/unsavedAdvisorChangesManager'
// Components
import Header from './components/Header'
import Review from './components/Review'
import Product from './components/Product'
import Button from '../../../ui-library/Button'
import CompanyInfo from './components/CompanyInfo'
import { Flex } from '../../../components/FlexBox'
import { Text } from '../../../components/common/Text'
import { DetailsSkeleton, HeaderSkeleton } from './components/Skeleton'
import { UnsavedChangesDialog } from '../../../components/layout/sharedComponents'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
import { ScreenContainerWithMenuHeader } from '../../../components/layout/ScreenContainer'
// Types
import {
  CallForSubmissionStepEnum,
  InnovationAccessKey,
  RoleEnum
} from '../../../types'
import { AnswerEntityTypeEnum } from '../../../types/form'

const StyledScrollView = styled(ScrollView)`
  height: 95%;
  flex-basis: 75%;
  margin-bottom: ${props => props.theme.space[3]}px;
`

export const Title = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights }, isSmallScreen }) => `
    color: ${colors.text2};
    fontSize: ${isSmallScreen ? fontSizes[4] : fontSizes[6]}px;
    fontWeight: ${fontWeights.regular};
`}
`

export const NextStep = styled(Title)`
  ${({ theme: { sizes } }) => `
  margin-right: ${sizes[4]}px;
`}
`

export const CreatePDFText = styled(Text)`
  ${({ theme: { colors, fontSizes, fontWeights } }) => `
    color: ${colors.text2};
    fontSize: ${fontSizes[3]}px;
    fontWeight: ${fontWeights.regular};
`}
`

const SubmissionDetailsScreen = () => {
  const { t } = useTranslation()
  const route: any = useRoute()
  const navigation: any = useNavigation()
  const isSmallScreen = useIsSmallScreen()
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [subToNavigate, setSubToNavigate] = useState<string | null>('')
  const [submissionId, setSubmissionId] = useState<string | null>(null)
  const tableName: string = useMemo(() => route?.params?.tableName, [
    route?.params?.tableName
  ])
  const { resetState } = useSetUnsavedAdvisorChanges()

  const dirtyForm = useRef<boolean>(false)

  useEffect(() => {
    setSubmissionId(route?.params?.submissionId)
  }, [route?.params?.submissionId])

  useFocusEffect(
    useCallback(() => {
      return () => {
        setSubmissionId(null)
      }
    }, [])
  )

  const callForSubmissionId = route?.params?.id
  const currentRole: RoleEnum =
    route?.name === 'AdviseSubmissionDetails'
      ? RoleEnum.InnovationAdvisor
      : RoleEnum.InnovationStaff

  const { callForSubmission, loading: loadingCFS } = useCallForSubmissionQuery(
    callForSubmissionId
  )
  const isCFSInSubmission =
    callForSubmission?.currentStep &&
    CallForSubmissionStepEnum.submission ===
      callForSubmission?.currentStep?.name

  const { submission, loading: loadingSubmission } = useSubmissionQuery(
    submissionId,
    currentRole === RoleEnum.InnovationStaff,
    {
      fetchPolicy:
        currentRole === RoleEnum.InnovationStaff && !isCFSInSubmission
          ? 'cache-first'
          : 'no-cache'
    }
  )

  const isDraftSubmission =
    submission?.step === CallForSubmissionStepEnum.submission ||
    isCFSInSubmission

  const navigate = (submissionToNavigate: string) => {
    navigation.setParams({ submissionId: submissionToNavigate })
  }

  const onDirty = (dirty: boolean) => {
    dirtyForm.current = dirty
  }

  const onDiscard = () => {
    resetState()
    setSubToNavigate(null)
    navigate(subToNavigate || '')
    dirtyForm.current = false
  }

  const onCloseDialog = () => {
    setSubToNavigate(null)
  }

  const onNavigateOnSubmissions = useCallback(
    (submissionToNavigate: string) => {
      if (dirtyForm.current) {
        setSubToNavigate(submissionToNavigate)
      } else {
        navigate(submissionToNavigate)
      }
    },
    [dirtyForm?.current]
  )

  const { formAnswer, loading: loadingFormAnswer } = useFormAnswerQuery(
    submissionId,
    AnswerEntityTypeEnum.InnovationSubmission
  )

  const loadingDetails =
    (loadingCFS && loadingSubmission && loadingFormAnswer) ||
    !submission ||
    !callForSubmission

  return (
    <ScreenContainerWithMenuHeader screenCategory="submissions">
      <StyledFlex>
        <Flex
          flexDirection={isSmallScreen ? 'column' : 'row'}
          flexWrap="wrap"
          w="100%"
          p={16}
          marginLeft={10}
          marginRight={10}
          style={{
            borderBottomWidth: 1,
            borderColor: '#d8d8d8'
          }}
        >
          <SkeletonContainer
            isLoading={loadingSubmission || !submission}
            Skeleton={HeaderSkeleton}
          >
            <Header
              callForSubmissionId={callForSubmissionId}
              submission={submission}
              role={currentRole}
              setSubmissionId={onNavigateOnSubmissions}
              tableName={tableName}
            />
          </SkeletonContainer>
        </Flex>
        <Flex
          flexDirection={isSmallScreen ? 'column' : 'row'}
          w="100%"
          flexBasis="100%"
        >
          {!isDraftSubmission && !isSmallScreen && (
            <Review
              role={currentRole}
              callForSubmission={callForSubmission}
              submission={submission}
              submissionReview={submission?.submissionReview}
              isLoading={loadingSubmission}
              onDirty={onDirty}
            />
          )}

          <StyledScrollView isSmallScreen={isSmallScreen}>
            <SkeletonContainer
              isLoading={loadingDetails}
              Skeleton={DetailsSkeleton}
            >
              <Flex>
                <Flex flexDirection="row" maxWidth="100%" width="100%">
                  <Flex
                    width="100%"
                    h="100%"
                    paddingLeft={20}
                    paddingRight={40}
                    paddingBottom={20}
                  >
                    <CompanyInfo submission={submission} />
                    <Product submission={submission} formAnswer={formAnswer} />
                  </Flex>
                </Flex>
              </Flex>
            </SkeletonContainer>
          </StyledScrollView>

          {!isDraftSubmission && isSmallScreen && (
            <Flex p={10} alignItems="flex-end">
              <Button
                title="Show Review Info"
                onPress={() => setShowReviewModal(true)}
              />
              <ReviewModal
                title={t('curation:submissionReview:title')}
                isOpen={showReviewModal}
                onClose={() => setShowReviewModal(false)}
              >
                <Review
                  role={currentRole}
                  callForSubmission={callForSubmission}
                  submission={submission}
                  submissionReview={submission?.submissionReview}
                  isLoading={loadingSubmission}
                  onDirty={onDirty}
                />
              </ReviewModal>
            </Flex>
          )}
        </Flex>
      </StyledFlex>
      <UnsavedChangesDialog
        description={t('common:dialogs:unsavedChanges:description')}
        showDialog={!!subToNavigate}
        setShowDialog={onCloseDialog}
        discardChangesCallback={onCloseDialog}
        saveChangesCallback={() => {}}
        actions={[
          {
            title: t('common:buttons:close'),
            type: 'outline',
            onPress: onCloseDialog
          },
          {
            title: t('common:dialogs:unsavedChanges:discardBtn'),
            onPress: onDiscard
          }
        ]}
      />
    </ScreenContainerWithMenuHeader>
  )
}

const SubmissionDetails = withNavigationPermissions(SubmissionDetailsScreen, [
  InnovationAccessKey.CURATE,
  InnovationAccessKey.ADVISE
])

export default SubmissionDetails
