import {
  LabelAndTitleCell,
  SubtitleCell
} from '../../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
import React from 'react'
import Theme from '../../../constants/Theme'
import { TouchableOpacity } from 'react-native'
import ArrowLeftIcon from '../../../components/icon/ArrowLeftIcon'
import { Flex } from '../../../components/FlexBox'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import styled, { useTheme } from 'styled-components/native'
import { Text } from '../../../components/common/Text'
import Link from '../../../components/Link'
import useTranslation from '../../../hooks/useTranslation'
import Button from '../../../ui-library/Button'
import Icon from '../../../components/icon'

export const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[6] - Theme.fontSizes[0]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'inline-block'
}

export const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}

export const Title = styled.Text`
  ${({ theme: { colors, space, fontWeights, fontSizes } }) => `
    color: ${colors.text1};
    fontWeight: ${fontWeights.bold};
    fontSize: ${fontSizes[6]}px;
    margin-bottom: ${space[2]}px;
  `}
`
export const Subtitle = styled.Text`
  ${({ theme: { colors, space, fontWeights, fontSizes } }) => `
    color: ${colors.text1};
    fontWeight: ${fontWeights.bold};
    fontSize: ${fontSizes[4]}px;
    margin-bottom: ${space[2]}px;
    margin-top: ${space[2]}px;
  `}
`

export const Content = ({ isSmallScreen, label, title, style = {} }) => {
  return isSmallScreen ? (
    <LabelAndTitleCell label={label} title={title} />
  ) : (
    <SubtitleCell style={style} text={title} />
  )
}

export const BackArrow = ({ routeName }) => {
  const linkToScreen = useLinkToScreen()
  const { colors, space } = useTheme()
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      w="100%"
      style={{
        paddingLeft: space[3],
        marginBottom: space[2]
      }}
    >
      <TouchableOpacity
        onPress={() => {
          linkToScreen(routeName)
        }}
        style={{
          marginRight: space[3] + space[1] // 20
        }}
      >
        <ArrowLeftIcon color={colors.lightGrey} />
      </TouchableOpacity>
    </Flex>
  )
}

export const ProfileContainer = styled(Flex)`
  ${({ theme: { space, radii, colors } }) => `
  border-radius: ${radii[4]}px;
  border: 1px solid ${colors.tints.grays.g100};
  padding: ${space[4]}px;
  background: ${colors.appBackground};
  `}
`

export const CompanyUrl = ({ url, title }) => {
  const { colors, space } = useTheme()
  const { t } = useTranslation()
  return (
    <Link
      to={url}
      title={t('curation:submissionDetails:company:website')}
      target="_blank"
      style={{
        textDecoration: 'none',
        marginBottom: space[2]
      }}
    >
      <Text
        styles={{
          color: colors.tints.grays.g400
        }}
      >
        {title}
      </Text>
    </Link>
  )
}

const MembersContainer = styled(Flex)`
  ${({ theme: { space, radii } }) => `
  border-radius: ${radii[3] + radii[2]}px;
  background-color: white;
  padding: ${space[2]}px ${space[3]}px;
  flex-direction: row;
  margin-bottom: ${space[2]}px;
  align-items: center;
  `}
`

const MemberColumn = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`
const Label = styled.Text`
  ${({ theme: { colors, space, fontSizes } }) => `
    color: ${colors.tints.grays.g400};
    fontSize: ${fontSizes[3]}px;
    margin-bottom: ${space[2]}px;
  `}
`

export const MemberPill = ({ innovator }) => {
  const { fontSizes, fontWeights, space } = useTheme()
  const { t } = useTranslation()

  return (
    <MembersContainer>
      <MemberColumn
        style={{
          flex: 1
        }}
      >
        <Text
          styles={{
            fontSize: fontSizes[4],
            fontWeight: fontWeights.bold
          }}
        >
          {innovator.person.firstName} {innovator.person.lastName}
        </Text>
        <Label
          style={{
            marginTop: space[1]
          }}
        >
          {innovator.person.emailAddresses?.length
            ? innovator.person.emailAddresses[0].email
            : ''}
        </Label>
      </MemberColumn>
      <Flex
        style={{
          justifyContent: 'flex-end'
        }}
      >
        <Text>{t(`settings:members:roles:${innovator.roles[0]}`)}</Text>
      </Flex>
    </MembersContainer>
  )
}

export const EditButton = ({
  title,
  route,
  params,
  containerStyle = {},
  testID = ''
}) => {
  const linkToScreen = useLinkToScreen()
  const { colors } = useTheme()
  return (
    <Flex
      style={{
        ...containerStyle
      }}
    >
      <Button
        testID={testID ?? undefined}
        icon={<Icon name="pencil" width={14} height={14} />}
        title={title}
        type="outline"
        buttonStyle={{
          borderColor: colors.primaryPalette.black,
          width: '100%'
        }}
        titleStyle={{
          color: colors.primaryPalette.black,
          textTransform: 'capitalize',
          width: 'fit-content'
        }}
        containerStyle={{
          width: '100%'
        }}
        iconStyle={{
          color: colors.primaryPalette.black
        }}
        hoverStyles={{
          iconStyle: {
            color: colors.primaryPalette.white
          },
          buttonStyle: {
            backgroundColor: colors.primaryPalette.black,
            borderColor: colors.primaryPalette.black
          },
          titleStyle: {
            color: colors.primaryPalette.white
          }
        }}
        onPress={() => {
          linkToScreen(route, params)
        }}
      />
    </Flex>
  )
}

export const ProductTitle = styled.Text`
  ${({ isSelected, theme: { colors, fontWeights, fontSizes } }) => `
    color: ${
      isSelected ? colors.primaryPalette.white : colors.primaryPalette.black
    };
    fontWeight: ${fontWeights.bold};
    fontSize: ${fontSizes[4]}px;
    margin-bottom: 0;
  `}
`

export const ProductContent = styled.Text`
  ${({
    isSelected,
    hasBeenPublished,
    theme: { colors, fontWeights, fontSizes, space }
  }) => `
    color: ${
      hasBeenPublished
        ? colors.success
        : isSelected
        ? colors.primaryPalette.white
        : colors.primaryPalette.black
    };
    fontWeight: ${fontWeights.regular};
    fontSize: ${fontSizes[3]}px;
    margin-bottom: ${space[1]}px;
  `}
  ${({ hasBeenPublished }) =>
    hasBeenPublished
      ? `
  display: inline-flex;
  align-self: flex-start;`
      : ``}
`

export const ProductContainer = styled(TouchableOpacity)`
  ${({ isSelected, theme: { space, radii, colors } }) => `
  position: relative;
  border-radius: ${radii[4]}px;
  background-color: ${
    isSelected ? colors.tints.grays.g500 : colors.appBackground
  };
  border: 1px solid ${
    isSelected ? colors.tints.grays.g500 : colors.tints.grays.g100
  };
  
  padding: ${space[3]}px ${space[3] + space[2]}px;
  flex-direction: row;
  margin-top: ${space[3]}px;
  align-items: center;
  justify-content: space-between;
  &:hover {
      background-color: ${colors.tints.grays.g500};
    }
  `}
`

const BannerContainer = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  ${({ theme, color }) => `
    background-color: ${color};
    padding: ${theme.space[3]}px;
    border-radius: ${theme.radii[3]}px;
  `};
`

const BannerText = styled.Text`
  margin-left: ${props => props.theme.space[2]}px;
  font-size: ${props => props.theme.fontSizes[3]}px;
  font-weight: ${props => props.theme.fontWeights.regular};
  color: ${props => props.theme.colors.tints.grays.g400};
`

enum BannerType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export const BannerAlert = ({
  type = BannerType.ERROR,
  message = ''
}: {
  type?: BannerType
  message: string
}) => (
  <BannerContainer color={type === BannerType.SUCCESS ? '#ecf9f8' : '#ffecf2'}>
    <Icon
      name={type === BannerType.SUCCESS ? 'check' : 'x-mark'}
      color={type === BannerType.SUCCESS ? '#1ec8b6' : '#ff375f'}
      width={14}
      height={14}
    />
    <BannerText>{message}</BannerText>
  </BannerContainer>
)
