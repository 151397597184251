import { clone } from 'ramda'
import { useSubscription } from '@apollo/client'
// Hooks
import useInnovationAccess from '../../../hooks/useInnovationAccess'
// Queries
import useUpdateCFSAfterReviewQuery from './useUpdateCFSAfterReviewQuery'
import { callForSubmissionQuery } from '../../Curation/hooks/useCallForSubmissionQuery'

const onUpdateCFSAfterReview = (client, { data }) => {
  try {
    const queryVariables = {
      id: data?.updateCFSAfterReview?.id,
      withSubmissionReviews: false,
      withSubmissionReviewsAverages: false,
      withInnovationSubmissions: false,
      filterIncomplete: false
    }

    const queryData = clone(
      client.readQuery({
        query: callForSubmissionQuery,
        variables: queryVariables
      })
    )

    if (queryData?.callForSubmission?.id) {
      const newSubmissionsState = data?.updateCFSAfterReview
        ?.innovationSubmissions
        ? [...data.updateCFSAfterReview.innovationSubmissions]
        : [...(queryData?.callForSubmission?.innovationSubmissions || [])]

      const newAdvisorsFavCount =
        data?.updateCFSAfterReview?.advisorsFavCount ||
        queryData?.callForSubmission?.advisorsFavCount

      client.writeQuery({
        query: callForSubmissionQuery,
        variables: queryVariables,
        data: {
          ...queryData,
          callForSubmission: {
            ...queryData?.callForSubmission,
            advisorsFavCount: newAdvisorsFavCount,
            innovationSubmissions: newSubmissionsState
          }
        }
      })
    }
  } catch (onUpdateCFSAfterReviewSubError) {
    console.error({ onUpdateCFSAfterReviewSubError })
  }
}

const useUpdateCFSAfterReviewSubscription = (id?: string) => {
  const innovationAccess = useInnovationAccess()
  useSubscription(useUpdateCFSAfterReviewQuery, {
    variables: { id, withInnovationSubmissions: innovationAccess.curate },
    skip: !id,
    onData: ({ client, data }) => {
      onUpdateCFSAfterReview(client, data as any)
    }
  })
}

export default useUpdateCFSAfterReviewSubscription
