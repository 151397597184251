import { gql } from '@apollo/client'

const currentUserQuery = gql`
  query {
    currentUser {
      id
      roles
      innovationOnboardedAt
      innovationEnabled
      innovationAccess {
        advise
        advisorAssignment
        curate
        directory
        callForSubmissionAdvisingSettings
        callForSubmissionSettings
        innovatorTeam
        platformManagement
        callForSubmissionTesting
        canCreateInnovatorAlumniMembership
      }
      person {
        id
        firstName
        lastName
        jobTitle
        salesforceId
        primaryGroup {
          id
          name
        }
        emailAddresses {
          email
        }
        photo {
          id
          cloudinaryId
        }
        innovationCompany {
          id
          name
          companyProfile {
            id
            url
            yearFounded
            funding
            employeeCount
            domains {
              id
              name
            }
            country {
              id
              name
            }
          }
          products {
            id
          }
        }
        companies {
          id
          name
          companyProfile {
            id
            url
            yearFounded
            funding
            employeeCount
            domains {
              id
              name
            }
          }
          products {
            id
          }
        }
      }
    }
  }
`

export default currentUserQuery
