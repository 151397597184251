import { useCallback } from 'react'

import { FormikProps } from 'formik'
import { useFocusEffect, useRoute } from '@react-navigation/native'
// Hooks
import { useSetUnsavedAdvisorChanges } from './unsavedAdvisorChangesManager'

const usePreventUnsavedAdvisorChanges = (
  formik: FormikProps<any>,
  isReadOnly: boolean
) => {
  const route = useRoute<any>()
  const {
    resetState,
    setHasUnsaved,
    setUnsavedAdvisorState
  } = useSetUnsavedAdvisorChanges()

  useFocusEffect(
    useCallback(() => {
      resetState()
      return () => {
        if (formik.dirty && !isReadOnly) {
          setUnsavedAdvisorState({
            submitForm: formik.submitForm,
            formRouteState: route
          })
          setHasUnsaved(true)
        }
      }
    }, [formik.dirty, formik.submitForm, isReadOnly])
  )
}

export default usePreventUnsavedAdvisorChanges
