import React from 'react'
import styled from 'styled-components/native'

import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useIsSmallScreen from '../../../hooks/useIsSmallScreen'

import { Body } from './LoginInfo'
import { H3 } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import { P } from '../../HomeScreen/content'
import Link from '../../../components/Link'
import theme from '../../../constants/Theme'

const Contact = styled.Text`
  color: 'rgba(255, 255, 255, 0.84)'
    ${({ theme: { isSmallScreen, sizes, fontWeights } }) => `
    font-weight: ${fontWeights.light}; 
    line-height: ${sizes[3] + sizes[2]}px;
    fontSize: ${isSmallScreen ? 14 : 18}px;
  `};
`
const CalendarRowContainer = styled.View`
  display: flex;
  flex-direction: row;
  ${({ isHomeScreen, theme: { sizes, radii, colors } }) => `
    height: ${sizes[4] - sizes[1]}px;
    border-radius: ${radii[3]}px;
    margin-top: ${sizes[1]}px;
    margin-bottom: ${sizes[1]}px;
    background: ${isHomeScreen ? colors.dullBlue : colors.darkBackground};
  `}
`

const DateContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  ${({ isHomeScreen, theme: { sizes, radii, colors } }) => `
    height: ${sizes[4] - sizes[1]}px;
    border-top-left-radius: ${radii[3]}px;
    border-bottom-left-radius: ${radii[3]}px;;
    padding-horizontal: ${sizes[1]}px;
    background: ${
      isHomeScreen ? colors.lightBlueBackground : colors.blueBackground
    };
  `}
`

const DelegationContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 67%;
  ${({ theme: { sizes } }) => `
    padding-horizontal: ${sizes[3] + sizes[1]}px;
  `}
`

const ShortNameContainer = styled.View`
  display: flex;
  flex-direction: row;
  ${({ theme: { sizes } }) => `
    width: ${sizes[4] - 2}px;
  `}
`

const Date = styled.Text`
  ${({ theme: { fontWeights, fontSizes, colors } }) => `
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes[3]}px;
    color:${colors.darkBackground};
  `}
`

const Delegation = styled.Text`
  ${({ isHomeScreen, theme: { fontWeights, fontSizes, colors } }) => `
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes[3]}px;
  color: ${isHomeScreen ? colors.darkBackground : colors.buttonText};
  `}
`

interface CalendarRowProps {
  date: string
  delegation: string
  delShortName: string
  isHomeScreen?: boolean
}

const CalendarRow = ({
  date,
  delegation,
  delShortName,
  isHomeScreen
}: CalendarRowProps) => {
  return (
    <CalendarRowContainer isHomeScreen={isHomeScreen}>
      <DateContainer isHomeScreen={isHomeScreen}>
        <Date>{date}</Date>
      </DateContainer>
      <DelegationContainer>
        <Delegation isHomeScreen={isHomeScreen}>{delegation}</Delegation>
        <ShortNameContainer>
          <Delegation isHomeScreen={isHomeScreen}>{delShortName}</Delegation>
        </ShortNameContainer>
      </DelegationContainer>
    </CalendarRowContainer>
  )
}

const SubmissionCalendar = ({ isHomeScreen, marginTop }) => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const isWindowCalendarEnabled = useFeatureFlag(
    'innovationDisplayWindowCalendar'
  )
  const isCalendarEnabledToHomeScreen = useFeatureFlag(
    'innovationWindowCalendarHomeScreen'
  )
  const isHpirCfsCalendarClose = useFeatureFlag('hpirCfsCalendarClose')
  const isIdirCfsCalendarClose = useFeatureFlag('idirCfsCalendarClose')
  const isLtirCfsCalendarClose = useFeatureFlag('ltirCfsCalendarClose')
  const isMtirCfsCalendarClose = useFeatureFlag('mtirCfsCalendarClose')
  const isPirCfsCalendarClose = useFeatureFlag('pirCfsCalendarClose')

  return (
    <Flex marginTop={marginTop} alignSelf={'flex-start'} width="100%">
      {!isHomeScreen ? (
        <H3
          styles={{ marginBottom: 16, fontWeight: '500' }}
          h3Style={{ fontSize: isSmallScreen ? '18px' : '20px' }}
        >
          {t('auth:login:submissionCalendar')}
        </H3>
      ) : null}
      {(isWindowCalendarEnabled && !isHomeScreen) ||
      (isCalendarEnabledToHomeScreen && isHomeScreen) ? (
        <>
          {!isIdirCfsCalendarClose ? (
            <CalendarRow
              date="NOW OPEN"
              delegation="HR & DEI"
              delShortName="IDIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {!isLtirCfsCalendarClose ? (
            <CalendarRow
              date="NOW OPEN"
              delegation="Legal Tech"
              delShortName="LTIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {!isMtirCfsCalendarClose ? (
            <CalendarRow
              date="NOW OPEN"
              delegation="Marketing Tech"
              delShortName="MTIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {!isPirCfsCalendarClose ? (
            <CalendarRow
              date="NOW OPEN"
              delegation="Procurement"
              delShortName="PIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {!isHpirCfsCalendarClose ? (
            <CalendarRow
              date="NOW OPEN"
              delegation="Health Plan"
              delShortName="HPIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          <CalendarRow
            date="JAN 09 - JAN 29"
            delegation="Provider Health"
            delShortName="PHIR"
            isHomeScreen={isHomeScreen}
          />
          <CalendarRow
            date="SUMMER 2025"
            delegation="Employer Health"
            delShortName="EHIR"
            isHomeScreen={isHomeScreen}
          />
          {isHpirCfsCalendarClose ? (
            <CalendarRow
              date="SUMMER 2025"
              delegation="Health Plan"
              delShortName="HPIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {isIdirCfsCalendarClose ? (
            <CalendarRow
              date="SUMMER 2025"
              delegation="HR & DEI"
              delShortName="IDIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {isLtirCfsCalendarClose ? (
            <CalendarRow
              date="SUMMER 2025"
              delegation="Legal Tech"
              delShortName="LTIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {isMtirCfsCalendarClose ? (
            <CalendarRow
              date="SUMMER 2025"
              delegation="Marketing Tech"
              delShortName="MTIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
          {isPirCfsCalendarClose ? (
            <CalendarRow
              date="SUMMER 2025"
              delegation="Procurement"
              delShortName="PIR"
              isHomeScreen={isHomeScreen}
            />
          ) : null}
        </>
      ) : !isHomeScreen ? (
        <Contact>
          <Body>{t('auth:login:submissionCalendarDisabled:beginning')}</Body>
          <Link
            to="mailto:innovator-support@world50.com"
            title={t('auth:login:submissionCalendarDisabled:email')}
            target="_blank"
            style={{
              color: theme.colors.brightPrimary,
              textDecoration: 'none'
            }}
          >
            {t('auth:login:submissionCalendarDisabled:email')}
          </Link>
          <Body>{t('auth:login:submissionCalendarDisabled:closing')}</Body>
        </Contact>
      ) : (
        <P>{t('homeScreen:noSubmissionwindows')}</P>
      )}
    </Flex>
  )
}

export default SubmissionCalendar
